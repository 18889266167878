<template>
    <div>
        <b-tabs
            vertical
            content-class="col-12 col-md-9 mt-1 mt-md-0"
            pills
            no-nav-style
            nav-wrapper-class="col-md-3 col-12"
            nav-class="nav-left"
        >
            <validation-observer ref="simpleRules">
<!--                PERSONAL INFO-->
                <b-tab active>
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="user"/>
                            </div>
                            <div>
                                <p class="m-0">
                                    <strong>{{ i18nT(`Mon compte`)}}</strong>
                                </p>
                                <p v-if="false" class="m-0 text-secondary">
                                    {{ i18nT(`Type, noms, addresse`)}}
                                </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Mon compte`)}}</h2>
                        </b-card-header>
                        <b-row class="border-bottom">
                            <b-col cols="12">
                                <b-row class="m-1">
                                    <b-col cols="12" md="12">
                                        <b-row>
                                            <b-col cols="12" md="12">
                                                <b-form-radio-group
                                                    v-model="landlordType"
                                                    name="tenantType"
                                                    class="mt-1 pr-1 ren-radio-type-buttons d-flex flex-column flex-md-row"
                                                >
                                                    <b-card no-body
                                                            class="ren-tenant-type d-flex mr-2 cursor-pointer"
                                                            :class="{'ren-type-border': landlordType =='person'}"
                                                            @click.prevent="changeType('person')"

                                                    >
                                                        <b-card-body
                                                            class="d-flex"
                                                        >
                                                            <b-form-radio :value="'person'"/>
                                                            <div class="flex-grow-1">
                                                                <div class='d-flex justify-content-between'>
                                                                    <h6>{{ i18nT(`Particulier`) }}</h6>
                                                                    <span class="text-muted">
                                                                <rt-icon icon="user" variant='light' :size="21"
                                                                         class="mr-1"/>
                                                            </span>
                                                                </div>
                                                                <p class="m-0">Insert Text here</p>
                                                            </div>
                                                        </b-card-body>
                                                    </b-card>
                                                    <b-card no-body
                                                            class="ren-tenant-type d-flex mr-2 cursor-pointer"
                                                            :class="{'ren-type-border': landlordType =='company'}"
                                                            @click.prevent="changeType('company')"
                                                    >
                                                        <b-card-body class="d-flex">
                                                            <b-form-radio :value="'company'"/>
                                                            <div class="flex-grow-1">
                                                                <div class='d-flex justify-content-between'>
                                                                    <h6>{{ i18nT(`Société`) }}</h6>
                                                                    <div class="text-muted">
                                                                        <rt-icon icon="briefcase" variant='light'
                                                                                 :size="21"
                                                                                 class="mr-1"/>
                                                                    </div>
                                                                </div>
                                                                <p class="m-0">Insert Text here</p>
                                                            </div>
                                                        </b-card-body>
                                                    </b-card>
                                                </b-form-radio-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row class="m-1">
                                    <b-col cols='12'>
                                        <!--                                TODO set up methods-->
                                        <avatar-control
                                            :profileData="landlordData"
                                            :parent-id="landlordData.LandlordID"
                                            @setNewAvatar="onSetNewAvatar"
                                            userType="landlord"
                                        />
                                    </b-col>
                                </b-row>
                                <b-row class="border-bottom">
                                    <b-col cols="12">
                                        <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Informations personnelles`) }}</h4>
                                    </b-col>
                                </b-row>
                                <b-row class="m-1">
                                    <b-col cols="12" md="12" class="my-1">
                                        <b-row>
                                            <b-col
                                                cols="12" md="6">
                                                <b-form-group :label="i18nT(`Civilité`)">
                                                    <v-select
                                                        v-model="landlordData.LandlordTitle"
                                                        :options="titleOptions"
                                                        label="text"
                                                        :clearable="false"
                                                        :reduce="(e) => e.value"
                                                        class="mb-1"
                                                        :placeholder="i18nT(`Choisir`)"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Prénom`)"
                                                    :class="{'required': landlordType === 'person'}"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Prénom`)"
                                                        :rules="landlordType==='person' ? required : null"
                                                    >
                                                        <b-form-input
                                                            id="First name"
                                                            v-model="landlordData.LandlordFirstName"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Deuxième prénom`)"
                                                >
                                                    <b-form-input
                                                        id="Middle name"
                                                        v-model="landlordData.LandlordMiddleName"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Nom`)"
                                                    :class="{'required': landlordType === 'person'}"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Nom`)"
                                                        :rules="landlordType==='person' ? required : null"
                                                    >
                                                        <b-form-input
                                                            id="Last name"
                                                            v-model="landlordData.LandlordLastName"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <!--                      TODO check date format when implemented-->

                                                <validation-provider
                                                    #default="{ errors }"
                                                    :name="i18nT(`Date de naissance`)"
                                                    rules="date_format"
                                                >
                                                    <b-form-group
                                                        :label="i18nT(`Date de naissance`)"
                                                    >
                                                        <date-picker
                                                            v-model="landlordData.LandlordBirthDate"
                                                            reset-button
                                                            :manual-input="true"
                                                            :state="errors.length > 0 ? false : null"
                                                        />
                                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Lieu de naissance`)"
                                                >
                                                    <b-form-input
                                                        v-model="landlordData.LandlordBirthPlace"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Mobile`)"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Mobile`)"
                                                        :rules="{ validPhoneNumber: mazphoneObj, required}"
                                                    >
                                                        <MazPhoneNumberInput
                                                            :key="phoneReady"
                                                            id="Phone"
                                                            default-country-code="FR"
                                                            v-model="landlordData.LandlordMobilePhone"
                                                            @update="updatePhoneObj"
                                                            :translations="{
                                                    countrySelectorLabel: i18nT(`Code du pays`),
                                                    countrySelectorError: i18nT(`Choisissez un pays`),
                                                    phoneNumberLabel: i18nT(`Numéro de téléphone`),
                                                    example: i18nT(`Example:`),
                                                  }"
                                                        />
                                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Telephone`)"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Telephone`)"
                                                        :rules="{ validPhoneNumber: mazphoneObj }"
                                                    >
                                                        <MazPhoneNumberInput
                                                            :key="phoneReady"
                                                            id="Phone"
                                                            default-country-code="FR"
                                                            v-model="landlordData.LandlordPhone"
                                                            @update="updatePhoneObj"
                                                            :translations="{
                                                    countrySelectorLabel: i18nT(`Code du pays`),
                                                    countrySelectorError: i18nT(`Choisissez un pays`),
                                                    phoneNumberLabel: i18nT(`Numéro de téléphone`),
                                                    example: i18nT(`Example:`),
                                                    }"
                                                        />
                                                        <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Site web`)"
                                                >
                                                    <b-form-input
                                                        id="Web site"
                                                        v-model="landlordData.LandlordWebsite"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row class="border-bottom" v-if="landlordType=='company'">
                                    <b-col cols="12">
                                        <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Informations société`) }}</h4>
                                    </b-col>
                                </b-row>
                                <b-row class="m-1" v-if="landlordType=='company'">
                                    <b-col
                                        cols="12"
                                        class="mt-1"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Société`)"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Prénom`)"
                                                        :rules="landlordType === 'company' ? required : null"
                                                    >
                                                        <b-form-input
                                                            id="Company name"
                                                            v-model="landlordData.LandlordCompanyName"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                                <p>
                                                    {{
                                                        i18nT(`Si vous remplissez ce champs, le nom de la société figurera sur les documents.`)
                                                    }}</p>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`No. TVA`)"
                                                >
                                                    <b-form-input
                                                        id="First name"
                                                        v-model="landlordData.LandlordVatNumber"
                                                    />
                                                </b-form-group>
                                                <p>
                                                    {{
                                                        i18nT(`Si vous remplissez ce champs, cette information figurera sur certains documents automatiques.`)
                                                    }}</p>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`RCS / SIREN`)"
                                                >
                                                    <b-form-input
                                                        id="First name"
                                                        v-model="landlordData.LandlordSiret"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Capital`)"
                                                >
                                                    <b-form-input
                                                        id="First name"
                                                        v-model="landlordData.Capital"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                    </b-col>

                                </b-row>
                                <b-row class="border-bottom">
                                    <b-col cols="12">
                                        <h4 class="mt-2 ml-1 mb-1">{{ i18nT(`Addresse`) }}</h4>
                                    </b-col>
                                </b-row>
                                <b-row class="m-1">
                                    <b-col
                                        cols="12"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Adresse`)"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Adresse`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="landlordData.LandlordAddress"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Adresse 2`)"
                                                >
                                                    <b-form-input
                                                        v-model="landlordData.LandlordAddress2"/>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Code postal`)"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Code postal`)"
                                                        :rules="{required, integer}"
                                                    >
                                                        <b-form-input
                                                            v-model="landlordData.LandlordZip"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Région`)"
                                                >
                                                    <b-form-input
                                                        v-model="landlordData.LandlordState"/>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Ville`)"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Ville`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="landlordData.LandlordCity"
                                                            :state="errors.length > 0 ? false:null"

                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"

                                            >
                                                <b-form-group
                                                    :label="i18nT(`Pays`)"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Pays`)"
                                                        rules="required"
                                                    >
                                                        <v-select
                                                            id="country"
                                                            :options="countries"
                                                            v-model="landlordData.LandlordCountry"
                                                            label="text"
                                                            :reduce="(e) => e.value"
                                                            :clearable="false"
                                                            :class="{ 'border-invalid': errors.length > 0 }"
                                                            :placeholder="i18nT(`Choisir`)"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols="12" class="float-right">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'tenants'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

<!--                LOGO-->
                <b-tab>
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="image"/>
                            </div>
                            <div>
                                <p class="m-0">
                                    <strong>{{ i18nT(`Logo principal`)}}</strong>
                                </p>
                                <p v-if="false" class="m-0 text-secondary">
                                    {{ i18nT(`Type, noms, addresse`)}}
                                </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Logo principal`)}}</h2>
                        </b-card-header>
                        <b-row class="m-1 border-bottom">
                            <b-col cols="12" md="12" class="my-1">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group :label="i18nT(`Logo`)" class="mb-50">
                                            <v-select
                                                v-model="landlordData.LandlordLogoType"
                                                :options="logoTypes"
                                                :clearable="false"
                                                label="text"
                                                :reduce="(e) => e.value"
                                                :placeholder="i18nT(`Choisir`)"
                                            />
                                        </b-form-group>
                                        <small>{{i18nT(`Ajouter le Logo de votre société pour personnaliser vos documents`)}}</small>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <div v-if="newLogoURL" class="mt-1">
                                            <!--                      TODO add methods for file upload-->
                                            <b-img
                                                :src="newLogoURL"
                                                fluid
                                                thumbnail
                                                alt="logo"
                                                class="mr-1"
                                            />
                                            <div class="mt-50">
                                                <b-button variant="outline-secondary" size="sm">{{i18nT(`Modifier`)}}</b-button>
                                                <b-button variant="outline-danger" size="sm" class="ml-50">
                                                    <rt-icon icon="trash" variant="light" :size="12"/>
                                                </b-button>
                                                <b-button variant="outline-primary" size="sm" class="ml-2">{{i18nT(`Valider`)}}</b-button>

                                            </div>
                                        </div>
                                        <div class='pb-1' >
                                            <!--                      TODO add methods for file upload-->
                                            <b-button
                                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                class="mt-0 mt-2 mr-1 mb-50"
                                                variant="outline-primary"
                                                @click="onFilePick('logo')"
                                                v-if="!newLogoURL"
                                            >
                                                <rt-icon icon="circle-plus" size="16" variant="light"/>
                                                {{ i18nT(`Parcourir`) }}
                                            </b-button>
                                            <b-form-file
                                                ref="logo"
                                                v-model="logo"
                                                class="hidden"
                                            />
                                            <br />
                                            <small class="mb-1">
                                                {{ i18nT(`Formats acceptés: GIF, JPG, PNG.`)}}<br/>
                                                {{i18nT(`Taille maximale: 15MB.`)}}
                                            </small>
                                        </div >
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" md="6" v-if="logoThumb">
                                        <b-form-group :label="i18nT(`Logo actuel`)">
                                            <b-img
                                                :src="logoThumb"
                                                fluid
                                                thumbnail
                                                alt="logo"
                                                class="mr-1 cursor-pointer"
                                                @click="openPreviewModal('logo')"
                                            />
                                            <div class="mt-1">
                                                <b-button
                                                    variant="outline-secondary"
                                                    class="mb-1 mr-1"
                                                    size="sm"
                                                >
                                                    {{i18nT(`Recadrer`)}}
                                                </b-button>
                                                <b-button
                                                    variant="outline-danger"
                                                    class="mb-1 mr-1"
                                                    size="sm"
                                                >
                                                    {{i18nT(`Supprimer`)}}
                                                </b-button>
                                            </div>
                                            <small>{{i18nT(`En supprimant votre logo vous allez revenir au logo par défaut Rentila.com`)}}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" class="float-right">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'tenants'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

<!--                SIGNATURE-->
                <b-tab>
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="file-signature"/>
                            </div>
                            <div>
                                <p class="m-0">
                                    <strong>{{ i18nT(`Signature`)}}</strong>
                                </p>
                                <p v-if="false" class="m-0 text-secondary">
                                    {{ i18nT(`Type, noms, addresse`)}}
                                </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Signature`)}}</h2>
                        </b-card-header>
                        <b-row class="m-1 border-bottom">
                            <b-col cols="12" md="12" class="my-1">
                                <b-row>
                                    <b-col cols="12" md="8">
                                        <div v-if="newSignatureURL">
                                            <!--                      TODO add methods for file upload-->
                                            <b-img
                                                :src="newSignatureURL"
                                                fluid
                                                thumbnail
                                                alt="signature"
                                                class="mr-1"
                                            />
                                            <div class="mt-50">
                                                <b-button variant="outline-secondary" size="sm">
                                                    {{ i18nT(`Modifier`) }}
                                                </b-button>
                                                <b-button variant="outline-danger" size="sm" class="ml-50">
                                                    <rt-icon icon="trash" variant="light" :size="12"/>
                                                </b-button>
                                                <b-button variant="outline-primary" size="sm" class="ml-2">
                                                    {{ i18nT(`Valider`) }}
                                                </b-button>

                                            </div>
                                        </div>
                                        <div class='pb-1'>
                                            <!--                      TODO add methods for signature upload-->
                                            <b-button
                                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                class="mt-0 mr-1 mb-50"
                                                variant="outline-primary"
                                                @click="onFilePick('signature')"
                                                v-if="!newSignatureURL"
                                            >
                                                <rt-icon icon="circle-plus" size="16" variant="light"/>
                                                {{ i18nT(`Parcourir`) }}
                                            </b-button>
                                            <b-form-file
                                                ref="signature"
                                                v-model="signature"
                                                class="hidden"
                                            />
                                            <br/>
                                            <small class="mb-1">
                                                {{ i18nT(`Ajouter votre signature scannée pour personnaliser vos documents`) }}<br/>
                                                {{ i18nT(`Formats acceptés: GIF, JPG, PNG. Taille maximale: 15 Mo`) }}
                                            </small>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="8">
                                        <b-button
                                            variant="outline-secondary"
                                            class="my-1"
                                            v-b-modal.signature-pad
                                        >
                                            {{ i18nT(`Dessiner la signature`) }}
                                        </b-button>
                                        <br />
                                        <small>{{ i18nT(`Vous pouvez également dessiner votre signature à la main à l'aide de de votre souris ou trackpad.`) }}</small></b-col>
                                </b-row>
                                <b-row class="mt-1" v-if="signatureThumb">
                                    <b-col cols="12" md="12">
                                        <b-form-group :label="i18nT(`Signature actuelle`)">
                                            <b-img
                                                :src="signatureThumb"
                                                fluid
                                                thumbnail
                                                alt="logo"
                                                class="mr-1 cursor-pointer"
                                                @click="openPreviewModal('signature')"
                                            />
                                            <div class="mt-1">
                                                <b-button
                                                    variant="outline-secondary"
                                                    class="mb-1 mr-1"
                                                    size="sm"
                                                >
                                                    {{i18nT(`Recadrer`)}}
                                                </b-button>
                                                <b-button
                                                    variant="outline-danger"
                                                    class="mb-1 mr-1"
                                                    size="sm"
                                                >
                                                    {{i18nT(`Supprimer`)}}
                                                </b-button>
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" class="float-right">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'tenants'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

<!--                IDENTITY PROOF-->
                <b-tab>
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="id-card"/>
                            </div>
                            <div>
                                <p class="m-0">
                                    <strong>{{ i18nT(`Pièce d'identité`)}}</strong>
                                </p>
                                <p v-if="false" class="m-0 text-secondary">
                                    {{ i18nT(`Type, noms, addresse`)}}
                                </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Pièce d'identité`)}}</h2>
                        </b-card-header>
                        <b-row class="m-1 border-bottom">
                            <b-col cols="12" md="12" class="my-1">
                                <b-row>
                                    <b-col
                                        cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Type`)"
                                        >
                                            <v-select
                                                v-model="landlordData.LandlordIDType"
                                                :options="identityTypes"
                                                label="text"
                                                :clearable="false"
                                                :reduce="(e) => e.value"
                                                class="mb-1"
                                                :placeholder="i18nT(`Choisir`)"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Numéro`)"
                                        >
                                            <b-form-input
                                                v-model="landlordData.LandlordIDNumber"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <validation-provider
                                            #default="{ errors }"
                                            :name="i18nT(`Expiration`)"
                                            rules="date_format"
                                        >
                                            <b-form-group
                                                :label="i18nT(`Expiration`)"
                                            >
                                                <date-picker
                                                    v-model="landlordData.LandlordIDExpiry"
                                                    reset-button
                                                    :manual-input="true"
                                                    :state="errors.length > 0 ? false : null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <div v-if="newIdentityDocURL" class="mt-1">
                                            <!--                      TODO add methods for file upload-->
                                            <b-img
                                                :src="newIdentityDocURL"
                                                fluid
                                                thumbnail
                                                alt="logo"
                                                class="mr-1"
                                            />
                                            <div class="mt-50">
                                                <b-button variant="outline-secondary" size="sm">{{i18nT(`Modifier`)}}</b-button>
                                                <b-button variant="outline-danger" size="sm" class="ml-50">
                                                    <rt-icon icon="trash" variant="light" :size="12"/>
                                                </b-button>
                                            </div>
                                        </div>
                                        <div class='pb-1' >
                                            <!--                      TODO add methods for file upload-->
                                            <b-button
                                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                class="mt-0 mt-2 mr-1 mb-50"
                                                variant="outline-primary"
                                                @click="onFilePick('identityDoc')"
                                                v-if="!newIdentityDocURL"
                                            >
                                                <rt-icon icon="circle-plus" size="16" variant="light"/>
                                                {{ i18nT(`Parcourir`) }}
                                            </b-button>
                                            <b-form-file
                                                ref="identityDoc"
                                                v-model="identityDoc"
                                                class="hidden"
                                            />
                                            <br />
                                            <small class="mb-1">
                                                {{ i18nT(`Copie de votre carte d'identité.`)}}<br/>
                                                {{i18nT(`Formats acceptés: Word, PDF, Images (GIF, JPG, PNG).`)}}
                                            </small>
                                        </div >
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" class="float-right">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'tenants'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

<!--                BANK INFO-->
                <b-tab>
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="money-check-dollar"/>
                            </div>
                            <div>
                                <p class="m-0">
                                    <strong>{{ i18nT(`Coordonnées bancaires`)}}</strong>
                                </p>
                                <p v-if="false" class="m-0 text-secondary">
                                    {{ i18nT(`Type, noms, addresse`)}}
                                </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Coordonnées bancaires`)}}</h2>
                        </b-card-header>
                        <b-row class="m-1 border-bottom">
                            <b-col cols="12" md="12" class="my-1">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Banque`)"
                                        >
                                            <b-form-input
                                                v-model="landlordData.LandlordBankName"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Adresse`)"
                                        >
                                            <b-form-input
                                                v-model="landlordData.LandlordBankAddress"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Ville`)"
                                        >
                                            <b-form-input
                                                v-model="landlordData.LandlordBankCity"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Code postal`)"
                                        >
                                            <b-form-input
                                                v-model="landlordData.LandlordBankZip"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        md="6"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Pays`)"
                                        >
                                            <v-select
                                                id="country"
                                                :options="countries"
                                                v-model="landlordData.LandlordBankCountry"
                                                label="text"
                                                :reduce="(e) => e.value"
                                                :clearable="false"
                                                :placeholder="i18nT(`Choisir`)"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`IBAN`)"
                                        >
                                            <b-form-input
                                                v-model="landlordData.LandlordBankIBAN"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`SWIFT/BIC`)"
                                        >
                                            <b-form-input
                                                v-model="landlordData.LandlordBankSwiftBic"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Numéro de compte`)"
                                        >
                                            <b-form-input
                                                v-model="landlordData.LandlordBankAccountNumber"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Code banque`)"
                                        >
                                            <b-form-input
                                                v-model="landlordData.LandlordBankCode"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Code guichet`)"
                                        >
                                            <b-form-input
                                                v-model="landlordData.LandlordBankBranchCode"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Clé rib`)"
                                        >
                                            <b-form-input
                                                v-model="landlordData.LandlordBankRIB"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" class="float-right">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'tenants'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

<!--                DELETE ACCOUNT-->
                <b-tab>
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="folder-xmark"/>
                            </div>
                            <div>
                                <p class="m-0">
                                    <strong>{{ i18nT(`Supprimer votre compte`)}}</strong>
                                </p>
                                <p v-if="false" class="m-0 text-secondary">
                                    {{ i18nT(`Type, noms, addresse`)}}
                                </p>
                            </div>
                        </div>
                    </template>
                    <b-card>
                        <b-card-header class="p-0">
                            <h2>{{i18nT(`Supprimer votre compte`)}}</h2>
                        </b-card-header>

                        <b-row class="m-1 border-bottom">
                            <b-col cols="12" md="12" class="my-2">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-button
                                            variant="outline-danger"
                                        >
                                            {{ i18nT(`Supprimer mon compte`) }}
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="10" class="p-0">
                                        <b-alert
                                            class="mt-1 ml-1"
                                            variant="primary"
                                            show
                                        >
                                            <div class="alert-body">
                                            <span>
                                                <b-badge variant="primary" class="mr-50">
                                                  {{ i18nT(`Info`) }}
                                                </b-badge>
                                                 {{ i18nT(`Avant de supprimer votre compte, veuillez vérifier que vous avez sauvegardé toutes vos données. Toutes ces informations seront supprimées définitivement.`) }}
                                            </span>
                                            </div>
                                        </b-alert>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" class="float-right">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'tenants'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>
            </validation-observer>
        </b-tabs>

        <!--         PREVIEW MODAL-->

        <b-modal
            id="preview"
            centered
            :hide-footer="true"
            size="lg"
        >
            <b-img
                :src="previewURL"
                alt="logo"
                class="mr-1"
                fluid
            />
        </b-modal>

        <!--        SIGNATURE PAD MODAL-->
        <b-modal
            id="signature-pad"
            @ok="saveSignature"
            :ok-title="i18nT(`Valider`)"
            :cancel-title="i18nT(`Annuler`)"
            static
            lazy
            cancel-variant="outline-secondary"
        >
            <div class="signature-pad-container">
                <VueSignaturePad
                    width="100%"
                    height="200px"
                    ref="signaturePad"
                    :options="{
                            onBegin: () => {$refs.signaturePad.resizeCanvas()},
                            minWidth: 1,
                            maxWidth: 2,
                        }"
                />
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BCol,
    BRow,
    BCard,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BModal,
    BImg,
    BTabs,
    BTab,
    BCardHeader,
} from "bootstrap-vue";
import vSelect from "vue-select";
import AvatarControl from "@/views/components/avatar/AvatarControl.vue";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required, email, integer} from '@validations';
import moment from "moment/moment";
import Ripple from "vue-ripple-directive";
import DatePicker from "@core/components/DatePicker.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {dictToSelectArray} from "@core/utils/utils";
import { MazBtn,MazPhoneNumberInput } from 'maz-ui'




extend('validPhoneNumber', {
    params: ['phoneNumberData'],
    validate: (value, maz) => {
        if(maz.phoneNumberData){
            return maz.phoneNumberData.isValid ;
        }else {
            return false;
        }
    },
    message: `Invalid phone number`,
});

extend('date_format', {
    validate(value) {
        return {
            required: true,
            valid: moment(value, this.dateFormat).isValid() && value.length === 10 && moment(value).isBetween('1900-01-01', '2999-12-31')
        };
    },
    message: `Invalid date.`,
})
extend('vselect' ,{
    validate (value) {
        return {
            required: true,
            valid: value !== undefined && value !== null && value != '-1'
        }
    },
    message: `Please select a country`
});

export default{
    components: {
        ValidationProvider,
        ValidationObserver,
        AvatarControl,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BCol,
        BRow,
        BCard,
        vSelect,
        DatePicker,
        BModal,
        BImg,
        BTabs,
        BTab,
        BCardHeader,
        MazBtn,
        MazPhoneNumberInput

    },
    directives: {
        Ripple,
    },
    data(){
        return{
            email,
            required,
            integer,
            mazphoneObj: {},
            landlordData: {},
            landlordType: '',
            titleOptions: [],
            countries: [],
            phoneReady:'no',
            identityTypes: [],
            logoTypes:[],
            logoType: {text: 'Choisir', value: '-1'},
            logo: null,
            logoThumb: '',
            logoURL: '',
            newLogoURL: '',
            signature: null,
            newSignatureURL: '',
            signatureURL: '',
            signatureThumb: '',
            previewURL: '',
            identityDoc: null,
            newIdentityDocURL: '',
            landlordImage: null,
            landlordImageURL: '',
        }
    },
    created(){

        this.$http.get('landlord/profile').then(({data})=> {
            this.landlordData = data.data.user
            this.landlordType = this.landlordData.LandlordType

            this.titleOptions=data.data.lists['titles'].map((e)=> {
                return {text: e.label, value: e.id}
            });

            this.identityTypes=data.data.lists['id_types'].map((e)=> {
                return {text: e.label, value: e.id}
            });

            this.countries=data.data.lists['countries'].map((e)=> {
                return {text: e.label, value: e.id}
            });

            this.logoTypes=data.data.lists['logo_types'].map((e)=> {
                return {text: e.label, value: e.id}
            });

            this.phoneReady = 'yes'

            if(data.data.user['logo_thumb_url']){
                this.logoThumb = data.data.user['logo_thumb_url']
            }
            if(data.data.user['signature_image_thumb_url']){
                this.signatureThumb = data.data.user['signature_image_thumb_url']
            }
            if(data.data.user['logo_url']){
                this.logoURL = data.data.user['logo_url']
            }
            if(data.data.user['signature_image_url']){
                this.signatureURL = data.data.user['signature_image_url']
            }
        })


    },
    methods: {
        changeType(type){
            this.landlordType = type;
        },
        updatePhoneObj(dataCollected){
            this.mazphoneObj = dataCollected;
        },
        onFilePick(type) {
            if(type && type == 'logo'){
                this.$refs['logo'].$refs.input.click()
            }
            if(type && type == 'signature'){
                this.$refs['signature'].$refs.input.click()
            }
            if(type && type == 'identityDoc'){
                this.$refs['identityDoc'].$refs.input.click()
            }
        },
        openPreviewModal(type){
            if(type && type ==='logo'){
                console.log('test')
                this.previewURL = this.logoURL
                this.$bvModal.show('preview')
            }
            if(type && type ==='signature'){
                this.previewURL = this.signatureURL
                this.$bvModal.show('preview')
            }
        },
        validationForm(){
            this.$refs.simpleRules.validateWithInfo().then(({isValid, errors, fields})=> {
                if(isValid) {

                    console.log('test')
                    const formData = new FormData();

                    // formData.append('id', this.landlordData.LandlordID);

                    // GENERAL INFO

                    formData.append('LandlordType', this.landlordType);
                    formData.append('LandlordTitle', this.landlordData.LandlordTitle);
                    formData.append('LandlordFirstName', this.landlordData.LandlordFirstName);
                    formData.append('LandlordMiddleName', this.landlordData.LandlordMiddleName);
                    formData.append('LandlordLastName', this.landlordData.LandlordLastName);
                    formData.append('LandlordBirthDate', this.landlordData.LandlordBirthDate? this.landlordData.LandlordBirthDate : null);
                    formData.append('LandlordBirthPlace', this.landlordData.LandlordBirthPlace);

                    //CONTACT INFO

                    formData.append('LandlordMobilePhone', this.landlordData.LandlordMobilePhone);
                    formData.append('LandlordPhone', this.landlordData.LandlordPhone);
                    formData.append('LandLordWebsite', this.landlordData.LandlordWebsite);

                    //ADDRESS

                    formData.append('LandlordAddress', this.landlordData.LandlordAddress);
                    formData.append('LandlordAddress2', this.landlordData.LandlordAddress2);
                    formData.append('LandlordZip', this.landlordData.LandlordZip);
                    formData.append('LandlordState', this.landlordData.LandlordState);
                    formData.append('LandlordCity', this.landlordData.LandlordCity);
                    formData.append('LandlordCountry', this.landlordData.LandlordCountry);

                    //TODO LOGO

                    formData.append('LandlordLogoType', this.landlordData.LandlordLogoType);

                    //TODO SIGNATURE

                    //TODO PHOTO

                    //IDENTITY PROOF

                    formData.append('LandlordIDType', this.landlordData.LandlordIDType);
                    formData.append('LandlordIDNumber', this.landlordData.LandlordIDNumber);
                    formData.append('LandlordIDExpiry', this.landlordData.LandlordIDExpiry ? this.landlordData.LandlordIDExpiry : null);
                    //TODO IDENTITY DOC

                    //COMPANY INFO

                    formData.append('LandlordCompanyName', this.landlordData.LandlordCompanyName);
                    formData.append('LandlordVatNumber', this.landlordData.LandlordVatNumber);
                    formData.append('LandlordSiret', this.landlordData.LandlordSiret);
                    formData.append('LandlordCapital', this.landlordData.LandlordCapital);


                    //BANK INFO

                    formData.append('LandlordBankName', this.landlordData.LandlordBankName);
                    formData.append('LandlordBankAddress', this.landlordData.LandlordBankAddress);
                    formData.append('LandlordBankCity', this.landlordData.LandlordBankCity);
                    formData.append('LandlordBankZip', this.landlordData.LandlordBankZip);
                    formData.append('LandlordBankCountry', this.landlordData.LandlordBankCountry);
                    formData.append('LandlordBankIBAN', this.landlordData.LandlordBankIBAN);
                    formData.append('LandlordBankSwiftBic', this.landlordData.LandlordBankSwiftBic);

                    //TODO ask for these last 3 fields to make sure the data is correct
                    formData.append('LandlordBankAccountNumber', this.landlordData.LandlordBankAccountNumber);
                    formData.append('LandlordBankCode', this.landlordData.LandlordBankCode);
                    formData.append('LandlordBankBranchCode', this.landlordData.LandlordBankBranchCode);
                    formData.append('LandlordBankRIB', this.landlordData.LandlordBankRIB);

                    this.$http.post('landlord/profile', formData).then(({data})=> {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(data),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.$router.push({ name: '/' })
                    }).catch(ex => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(ex),
                                icon: 'InfoIcon',
                                variant: 'danger'
                            }
                        })
                    })
                }else {
                    let y = 1000000
                    let prevY = 1000000
                    for (const key in errors) {
                        if (errors[key].length > 0) {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: errors[key][0],
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                            // console.log(fields[key])
                            const el = document.getElementById(fields[key].id)
                            prevY =
                                el.getBoundingClientRect().top + window.scrollY - 150
                            if (prevY <= y) y = prevY
                        }
                    }
                    if (y < 1000000) {
                        window.scrollTo({ top: y, behavior: 'smooth' })
                    }
                }
            })
        },
        saveSignature(){
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

            //converts the data to a js file object
            function base64ToImageFile(base64, filename) {

                const block = base64.split(";")
                const contentType = block[0].split(":")[1];
                const realData = block[1].split(",")[1];
                const byteArray = new Uint8Array(atob(realData).split('').map(char => char.charCodeAt(0)));
                const blob = new Blob([byteArray], { type: contentType });
                const file = new File([blob], filename, { type: contentType });

                return file;
            }
            if(!isEmpty){
                const signatureFile = base64ToImageFile(data, 'signature.png');

                this.signature = signatureFile;
            }
        },
        onSetNewAvatar(newData) {
            this.landlordImage = newData.newUserImage;
            this.landlordImageURL = newData.newImageUrl;
            this.landlordData = newData.newProfileData;
        },
    },

    watch: {
        logo: function (val) {
            if (val) {
                console.log("val: ", val)
                this.logo = val
                this.newLogoURL = URL.createObjectURL(val)
            } else {
                this.logo= null
                this.newLogoURL = ''
            }
        },
        signature: function (val) {
            if (val) {
                console.log("val: ", val)
                this.signature = val
                this.newSignatureURL = URL.createObjectURL(val)
            } else {
                this.signature= null
                this.newSignatureURL = ''
            }
        },
        identityDoc: function (val) {
            if (val) {
                console.log("val: ", val)
                this.identityDoc = val
                this.newIdentityDocURL = URL.createObjectURL(val)
            } else {
                this.identityDoc= null
                this.newIdentityDocURL = ''
            }
        },
    }

}
</script>


<style lang="scss">

@import 'maz-ui/lib/css/maz-phone-number-input';

@media(min-width: 1500px) {
    .ren-radio-type-buttons {
        max-width: 50%;
    }
}

.signature-pad-container {
    outline: 1px solid gray;
}

.border-invalid .vs__dropdown-toggle {
    border: 0.5px solid #ea5455;
}

.dark-layout .border-invalid .vs__dropdown-toggle {
    border: 0.5px solid #ea5455;
}

</style>
